html, body {
    max-width: 100%;
    min-width: 400px;
    overflow-x: hidden;
}
.next-steps .fa-link {
  margin-right: 5px;
}

/* Fix for use only flexbox in content area */
.next-steps .row {
  margin-bottom: 0;
}

.next-steps .col-md-5 {
  margin-bottom: 3rem;
}

@media (max-width: 768px) {
  .next-steps .col-md-5 {
    margin-bottom: 0;
  }
}

ul,li {
  list-style-type: none;
}

.rc-slider {
  position: relative;
  padding-top: 7px;
  width: 100%;
  border-radius: 6px;
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider * {
  box-sizing: border-box;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.rc-slider-rail {
  height: 4px;
  margin-top: -2px;
  width: 100%;
  background-color: #e9e9e9;
}
.rc-slider-track {
  height: 4px;
  margin-top: -4px;
  background-color: rgba(0, 0, 0, 0.54);
}
.rc-slider-handle {
  position: absolute;
  margin-top: -8px;
  width: 10px;
  height: 10px;
  cursor: pointer;
  border-radius: 50%;
  border: solid 3px rgba(0, 0, 0, 0.54);;
  background-color: #fff;
  z-index: 10;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.rc-slider-handle:focus {
  outline: 0!important;
}
.rc-slider-handle-active:active {
  border-color: #57c5f7;
  box-shadow: 0 0 5px #57c5f7;
}
.rc-slider-mark-text {
  max-width: 100%;
  margin-top: 0.5em;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  cursor: pointer;
  color: #999;
}
.rc-slider-mark-text-active {
  color: #666;
}
.rc-slider-scale {
  position: relative;
  width: 100%;
  background: transparent;
}
.rc-slider-scaleElements {
  margin-top: -2px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.rc-slider-scaleElement {
  margin-top: -4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.rc-slider-dot {
  width: 8px;
  height: 8px;
  border: 2px solid #e9e9e9;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  z-index: 1;
}
.rc-slider-dot-active {
  border-color: #96dbfa;
}
.rc-slider-disabled .rc-slider-handle,
.rc-slider-disabled .rc-slider-dot {
  border-color: #e9e9e9;
}
